<template>
  <v-row class="mx-auto">
    <v-col
      class="pt-1 pb-0"
      cols="12"
    >
      <v-card
        class="my-0"
        id="third-step-expurgo-confirm"
      >
        <v-toolbar
          color="primary"
          dark
          dense
          flat
        >
          <v-toolbar-title class="primary text-tabs-expurgo">
            <span>Verifique as informações e confirme</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-tabs-expurgo">
          Tem certeza que deseja apagar as linhas selecionadas?
        </v-card-text>
        <v-card-text class="text-tabs-expurgo">
          Nº de Linhas:
          <b style="color: red">{{ records | parseNumberToIntegerBR }}</b>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon large> mdi-alert </v-icon>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col
      class="pt-1 pb-0 mt-5"
      cols="12"
    >
      <v-checkbox
        class="checkbox-change-view"
        v-model="arquivarBdgd"
        label="Arquivar as linhas de dados antes de efetuar o expurgo"
      >
      </v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    records: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      arquivarBdgd: false
    };
  },
  watch: {
    arquivarBdgd(value) {
      this.$emit('arquivarBdgdAlterado', value);
    }
  }
};
</script>

<style>
.text-tabs-expurgo {
  color: #333 !important;
  font-size: 17px !important;
  font-weight: 300 !important;
}

.text-tabs-expurgo span {
  color: #fff !important;
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  line-height: 1.4em !important;
  letter-spacing: 0.0073529412em !important;
}
</style>
